const localSection = document.querySelectorAll(".local");

if (localSection && localSection.length > 0) {
    const handleBannerMarginTop = (banner) => {
        banner.style.marginTop = `-${window.innerWidth > 991.98 ? banner.offsetHeight / 2 : banner.offsetHeight / 3}px`;
    }
    localSection.forEach(section => {
        const banner = section.querySelector(".local__banner");

        if (banner) {
            handleBannerMarginTop(banner);
            window.addEventListener("resize", () => handleBannerMarginTop(banner));
        }

        const triggerContactBtn = section.querySelector(".js-trigger-side-menu");

        if (triggerContactBtn) {
            const showFormButton = document.querySelector(".js-show-form");
            if (showFormButton) {
                triggerContactBtn.addEventListener("click", () => {
                    showFormButton.click();
                })
            }
        }
    });
}